import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Paper from '@mui/material/Paper';

export default function Home() {
  return (
    <>  
      <div>
        <Typography gutterBottom variant="h4" component="div" align='center' sx={{ fontWeight: 'bold'}}>
          ¡Bienvenidos a Gasolinera TECAMAC!
        </Typography>
        <div className='home-line' />
      </div>
      <div className='home-container'>
        <Card sx={{ maxWidth: 300 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              className='card-image'
              image="./super.png"
              alt="gasolina super"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" align='center'>
                Gasolina regular 87 Octanos
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card sx={{ maxWidth: 300 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              className='card-image'
              image="./premium.png"
              alt="gasolina super"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" align='center'>
                Gasolina Premium mayor o igual a 91 Octanos
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card sx={{ maxWidth: 300 }}>
          <CardActionArea>
            <CardMedia
              component="img"
              className='card-image'
              image="./diesel.png"
              alt="gasolina super"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" align='center'>
                Diesel Automotriz
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>

      <div className='banner-container'>
        <img alt='banner1' src='./banner1.png'/>
      </div>

      <div className='payment-container'>
        <Typography gutterBottom variant="h5" component="div" align='center' sx={{ fontWeight: 'bold'}}>
          Formas de pago
        </Typography>
        <Paper elevation={3} className='payment-paper'>
          <img className='payment-img' alt='visa' src='./visa.png' />
          <img className='payment-img' alt='visa' src='./mastercard.png' />
          <img className='payment-img' alt='visa' src='./transferencia.png' />
        </Paper>
      </div>

      <div className='banner-container1'>
        <img alt='banner2' src='./banner2.png'/>
      </div>
    </>
  )
}
