import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

const theme = createTheme({
   palette: {
      primary: {
      main: "#004e98",
      },
      secondary: {
      main: "#fff",
      },
      third: {
      main: "#002f5b",
      },
      disabled: {
      main: "#3371ad",
      },
      error: {
      main: "#da300f",
      },
      lightred: {
      main: "#da300f",
      },
      white: {
      main: "#FFFFFF",
      },
      black: {
      main: "#000000",
      },
      gray: {
      main: "#666666",
         },
      grayed: {
      main: "#E6E6E6",
      },
   },

   components: {
      LinkTab: {
      defaultProps: {
         textTransform: "none",
      },
      },
      MuiButton: {
      variants: [
         {
            props: { variant: "circulo" },
            style: {
               background: "#833177",
               borderRadius: "50%",
               minWidth: "10px",
               padding: "6px 6px",
            },
         },
      ],
      },
   },
});

export default theme;
