import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

export default function Contact() {
   return (
      <div>
         <Typography
            gutterBottom
            variant="h5"
            component="div"
            align="center"
            sx={{ fontWeight: "bold" }}
         >
            Visítanos
         </Typography>

         <center>
            <iframe
               src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13195.92942424294!2d-99.00797027370491!3d19.676679554271278!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ed788afb34d7%3A0xb5cd5ba1bbcaa066!2sCLOVER%20GASOLINERA!5e0!3m2!1ses!2smx!4v1689049704503!5m2!1ses!2smx"
               className="contact-map"
               title="maps"
               height="300"
               style={{ border: 0 }}
               allowfullscreen=""
               loading="lazy"
               referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
         </center>

         <div className="contact-container-paper">
            <Paper elevation={3}  className="contact-paper">
               <p className="contact-title">Nombre de la empresa: </p>
               <p>Gasolinera Tecamac S.A. de C.V.</p>
               <p className="contact-title">Dirección: </p>
               <p>Calle Reforma número exterior 38, Colonia San Pedro Atzompa, Tecámac Felipe Villanueva, Estado de México C.P. 55770</p>
               <p className="contact-title">Quejas y Sugerencias:</p>
               <p>estacion.clover@gmail.com</p>
            </Paper>
            <Paper elevation={3} className="contact-paper-1">
               <p className="contact-title">RFC:</p>
               <p>GTE120606BW4</p>
               <p className="contact-title">Correo electronico:</p>
               <p>estacionP20806@gasclover.com.mx</p>
               <p>facturacion@gasclover.com.mx</p>
               <p className="contact-title">Teléfono: </p>
               <p>55 5935 - 1759</p>
            </Paper>
         </div>
      </div>
   );
}
