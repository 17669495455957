import React from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import GavelIcon from '@mui/icons-material/Gavel';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

export default function About() {
  return (
    <>
      <div className='about-container'>
        <div>
          <img className='about-img' alt='view for the street advert' src='./street.jpeg'/>
        </div>
        <div className='about-description'>
          <Typography gutterBottom variant="h5" component="div" align='center' sx={{ fontWeight: 'bold'}}>
            <span className='about-span'>GASOLINERA TECAMAC</span>
          </Typography>
          <div className='about-line'/>
          <Typography gutterBottom variant="p" component="div" align='justify' sx={{ paddingTop: '1rem'}}>
            Tambien conocida como Gasolinera Clover
          </Typography>
          
          <Typography gutterBottom variant="p" component="div" align='justify' sx={{ paddingY: '1rem'}}>
            Somos una Gasolinera que está comprometida a ofrecer el mejor servicio y productos de calidad con finalidad de satisfacer las necesidades de los clientes, dando litros completos.
          </Typography>
          <div className='about-line'/>
        </div>
      </div>

      <div className='about-container-1'>
        <Typography gutterBottom variant="h6" component="div" align='center' sx={{ fontWeight: 'bold', color: '#fff'}}>
          Nuestros Valores
        </Typography>
        <div className='about-container-paper'>
          <Paper elevation={3} className='about-paper'>
            <GavelIcon sx={{ fontSize: 40 }} color="lightred"/> Honestidad
          </Paper>
          <Paper elevation={3} className='about-paper'>
            <Diversity3Icon sx={{ fontSize: 40 }} color="lightred"/> Amabilidad
          </Paper>
          <Paper elevation={3} className='about-paper'>
            <SupportAgentIcon sx={{ fontSize: 40 }} color="lightred"/> Compromiso
          </Paper>
          <Paper elevation={3} className='about-paper'>
            <AssignmentTurnedInIcon sx={{ fontSize: 40 }} color="lightred" /> Responsabilidad
          </Paper>
        </div>
      </div>

      <div>
        <div>
          <Typography gutterBottom variant="h6" component="div" align='center' sx={{ fontWeight: 'bold' }} color='primary' >
            Nuestros productos
          </Typography>
            <div className='home-container'>
              <Card sx={{ maxWidth: 300, boxShadow: '10px 10px #2a64aa', border: '1.5px solid rgba(0,0,0,0.14)' }}>
                  <CardMedia
                    component="img"
                    className='card-image'
                    image="./super.png"
                    alt="gasolina super"
                  />
              </Card>

              <Card sx={{ maxWidth: 300, boxShadow: '10px 10px #da3010', border: '1.5px solid rgba(0,0,0,0.14)' }}>
                  <CardMedia
                    component="img"
                    className='card-image'
                    image="./premium.png"
                    alt="gasolina super"
                  />
              </Card>

              <Card sx={{ maxWidth: 300, boxShadow: '10px 10px #5b5a5d', border: '1.5px solid rgba(0,0,0,0.14)' }}>
                  <CardMedia
                    component="img"
                    className='card-image'
                    image="./diesel.png"
                    alt="gasolina super"
                  />
              </Card>
            </div>
        </div>
        <Typography gutterBottom variant="p" component="div" align='center' sx={{ fontWeight: 'bold', marginTop:'3rem' }} color='primary' >
          Tambien te ofrecemos variedad de Aceites y lubricantes de diversas marcas.
        </Typography>
        <Typography gutterBottom variant="p" component="div" align='center' sx={{ fontWeight: 'bold' }} color='primary' >
          Contamos con personal capacitado a fin de ofrecerte un servicio eficaz y de excelencia.
        </Typography>
      </div>
    </>
  )
}
