import React from "react";
import "./Footer.css";
import Link from '@mui/material/Link';

export default function Footer() {
   return (
      <div>
         <div className="footer-img" />
         <div className="footer-container">
            <div className="footer-body">
               <img className='footer-image' alt='logo clover' src="./clover.png"/>
               <Link href="http://clover.dynns.com:8005/ControlGasFE/FEinicio.aspx" color="inherit">
                  Facturación en línea
               </Link>
            </div>
            <p>© 2023.Todos los derechos reservados</p>
         </div>
      </div>
   );
}
