import React from "react";
import "./Header.css";

export default function Header() {
   return (
      <>
         <div className="header-line" />
         <div className="header-container">
            <img className="header-img" src="./clover.png" alt="clover img" />
         </div>
      </>
   );
}
