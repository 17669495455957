import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export default function Billing() {
   return (
      <div>
         <div className="about-container">
            <div className="about-description">
               <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  align="center"
                  sx={{ fontWeight: "bold" }}
               >
                  <span className="billing-span">
                     Gracias por cargar con nosotros
                  </span>
               </Typography>
               <div className="billing-line" />
               <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  align="justify"
                  sx={{ paddingTop: "1rem" }}
               >
                  En muy pocos pasos podrás emitir tu factura.
               </Typography>
               <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  align="justify"
                  sx={{ paddingY: "1rem" }}
               >
                  Recomendaciones: 
                  <ul>
                     <li>Tener tu CONSTANCIA DE SITUACIÓN FISCAL
                     actualizado al momento de facturar.</li>
                     <li>Recuerde que la razón social debe ir sin régimen de capital:</li>
                     <p>Correcto: Empresa</p>
                     <p>Incorrecto: Empresa SA de CV</p>
                  </ul>

               </Typography>
               <center className="billing-padding">
                  
                     <a
                        href="http://clover.dynns.com:8005/ControlGasFE/FEinicio.aspx"
                        target="_blank"
                        rel="noreferrer"
                     ><Button variant="contained">
                        Facturar ahora
                     </Button></a>
                  
               </center>
               <div className="billing-line" />
            </div>
            <div>
               <img
                  className="billing-img"
                  alt="view for the street advert"
                  src="./billing.jpg"
               />
            </div>
         </div>
      </div>
   );
}
