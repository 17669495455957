import React from 'react'

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Menu from './components/Menu/Menu'

import './App.css'

function App() {
  return (
    <div>
      <Header/>
      <Menu />
      <Footer/>
    </div>
  );
}

export default App;
